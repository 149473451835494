import { Button, Modal } from '@blissbook/ui/lib'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import pluralize from 'pluralize'
import React from 'react'

export const SignHistoryModal = Modal.wrap(({ onClose, handbook }: any) => {
  const signatures = orderBy(handbook.signatures, 'id', 'desc')
  return (
    <Modal.Component
      className='signature-history'
      onClose={onClose}
      position='center'
      width={400}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Signature History</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className='tw-text-gray-500'>
            You've acknowledged your receipt of the {handbook.name}{' '}
            {pluralize('time', handbook.signatures.length, true)}.
          </p>

          <div style={{ fontSize: 14 }}>
            {signatures.map((signature) => (
              <div
                key={signature.id}
                className='tw-flex tw-items-center tw-justify-between tw-mb-4'
              >
                <div
                  className='text-left tw-text-gray-500'
                  style={{ fontWeight: 'bold' }}
                >
                  Version {signature.handbookVersion}:
                </div>

                <a
                  href={`/d/${handbook.id}-${handbook.slug}/signatures/${signature.id}/print.pdf?section=acknowledgement`}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  {moment(signature.singedAt).format('M/D/YYYY h:mma')}
                </a>
              </div>
            ))}
          </div>

          <div className='form-submit tw-justify-center'>
            <Button color='dark' onClick={onClose} size='sm'>
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Component>
  )
})
