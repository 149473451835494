import { isSignatureOverdue } from '@blissbook/common/handbook/statistics'
import { getDaysLeft } from '@blissbook/common/handbook/util/signatures'
import type { ManagerPersonHandbook } from '@blissbook/ui/application/graph'

import moment from 'moment'
import pluralize from 'pluralize'

type DirectReportStatus = {
  className: string
  type: string
  formatTooltip: (handbook: ManagerPersonHandbook) => string
  icon: [string, string]
}

export const validStatus: DirectReportStatus = {
  className: 'tw-text-green-700',
  type: 'valid',
  formatTooltip: (handbook) => {
    const { lastSignedAt } = handbook

    return `Signed on ${moment(lastSignedAt).format('M/D/YYYY')}`
  },
  icon: ['far', 'check'],
}

export const signatureNotRequired: DirectReportStatus = {
  className: 'tw-text-gray-400',
  type: 'not-required',
  formatTooltip: () => {
    return 'Signature is not required'
  },
  icon: ['far', 'circle'],
}

export const noDueDateStatus: DirectReportStatus = {
  className: 'tw-text-sunshine-700',
  type: 'no-due-date',
  formatTooltip: () => {
    return 'Has not signed yet'
  },
  icon: ['far', 'circle'],
}

export const duePast7DaysStatus: DirectReportStatus = {
  className: 'tw-text-sunshine-700',
  type: 'due-past-7-days',
  formatTooltip: (handbook) => {
    const { dueDate } = handbook

    const daysLeft = getDaysLeft(dueDate)

    return `Due in ${pluralize('day', daysLeft, true)}`
  },
  icon: ['far', 'clock'],
}

export const dueWithin7DaysStatus: DirectReportStatus = {
  className: 'tw-text-sunshine-800',
  type: 'due-within-7-days',
  formatTooltip: (handbook) => {
    const { dueDate } = handbook

    const daysLeft = getDaysLeft(dueDate)

    if (daysLeft === 0) return 'Due today'

    if (daysLeft === 1) return 'Due tomorrow'

    return `Due in ${pluralize('day', daysLeft, true)}`
  },
  icon: ['far', 'clock'],
}

export const overdueStatus: DirectReportStatus = {
  className: 'tw-text-red-700',
  type: 'overdue',
  formatTooltip: (handbook) => {
    const { dueDate } = handbook

    const daysLeft = getDaysLeft(dueDate)

    return `${pluralize('day', -daysLeft, true)} overdue`
  },
  icon: ['far', 'circle-exclamation'],
}

export function getDirectReportRecipientStatus(
  handbook: ManagerPersonHandbook,
) {
  const { dueDate, hasValidSignature, isSignatureRequired } = handbook

  if (hasValidSignature) return validStatus

  if (!isSignatureRequired) return signatureNotRequired

  if (!dueDate) return noDueDateStatus

  // Overdue
  const isOverdue = isSignatureOverdue(new Date(dueDate))
  if (isOverdue) return overdueStatus

  // Check based on number of days left
  const daysLeft = getDaysLeft(dueDate)
  const isDueWithin7Days = daysLeft < 7 && daysLeft >= 0

  return isDueWithin7Days ? dueWithin7DaysStatus : duePast7DaysStatus
}

export const getDocumentsSignNumbers = (handbooks: ManagerPersonHandbook[]) => {
  const numbers = {
    signed: 0,
    unsigned: {
      dueWithin7Days: 0,
      duePast7Days: 0,
      overdue: 0,
      total: 0,
    },
    total: 0,
  }

  handbooks?.forEach((handbook) => {
    if (handbook.hasValidSignature) {
      return numbers.signed++
    }

    if (!handbook.isSignatureRequired) {
      return
    }

    if (!handbook.dueDate) {
      numbers.unsigned.overdue++
      numbers.unsigned.total++
      return
    }

    if (isSignatureOverdue(handbook.dueDate)) {
      numbers.unsigned.overdue++
      numbers.unsigned.total++
      return
    }

    const daysLeft = getDaysLeft(handbook.dueDate)

    if (daysLeft < 7) {
      numbers.unsigned.dueWithin7Days++
      numbers.unsigned.total++
      return
    }
    numbers.unsigned.duePast7Days++
    numbers.unsigned.total++
    return
  })

  numbers.total = numbers.signed + numbers.unsigned.total

  return numbers
}
