import { getContrastColor } from '@blissbook/common/branding/color'
import { formatAnnotationBookmark } from '@blissbook/lib/blissbook'
import { colors, gridBreakpoints } from '@blissbook/ui/branding'
import {
  SignHistoryModal,
  transitionDuration,
} from '@blissbook/ui/dashboard/components'
import {
  useAudienceDashboardSettings,
  useDirectReports,
} from '@blissbook/ui/dashboard/hooks'
import {
  FeatureFlagId,
  useFeatureFlagEnabled,
} from '@blissbook/ui/dashboard/hooks/useFeatureFlagEnabled'
import type { DirectReportPerson } from '@blissbook/ui/dashboard/store'
import { Link, Tooltip } from '@blissbook/ui/lib/index'
import { css } from '@emotion/react'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import last from 'lodash/last'
import moment from 'moment'
import pluralize from 'pluralize'
import React from 'react'
import { Fragment } from 'react'
import tinycolor from 'tinycolor2'

type AudienceHandbookProps = {
  handbook: IHandbook
}

const Badge: React.FC<{
  className?: string
  style?: React.CSSProperties
}> = (props) => {
  const { accentColor } = useAudienceDashboardSettings()
  return (
    <div
      {...props}
      css={{
        alignItems: 'center',
        backgroundColor: accentColor,
        borderRadius: 2,
        color: getContrastColor(accentColor),
        display: 'inline-flex',
        fontSize: 14,
        justifyContent: 'center',
        minWidth: 22,
        paddingLeft: 4,
        paddingRight: 4,
      }}
    />
  )
}

const HandbookIndicator: React.FC<{
  count?: number
  icon: IconProp
  label?: string
  to?: string
  tooltip: string
}> = ({ count, icon, label, to, tooltip }) => (
  <Fragment>
    <Tooltip content={tooltip} maxWidth={320} placement={'bottom'}>
      <div
        className='tw-hidden lg:tw-block lg:tw-ml-6'
        style={{ position: 'relative' }}
      >
        <Link className='btn-icon' to={to}>
          <FontAwesomeIcon
            icon={icon}
            style={{ color: colors['gray-400'], fontSize: 30 }}
          />
        </Link>

        {count > 0 && (
          <Badge
            className='tw-uppercase tw-hidden lg:tw-flex'
            style={{
              position: 'absolute',
              bottom: -8,
              right: -8,
            }}
          >
            {count}
          </Badge>
        )}
      </div>
    </Tooltip>

    {label && (
      <div className='lg:tw-hidden tw-mt-1'>
        {count > 0 ? (
          <>
            <Badge className='tw-uppercase'>{count}</Badge> {label}
          </>
        ) : (
          label
        )}
      </div>
    )}
  </Fragment>
)

const getUnsignedPeopleCount = (
  directReports: DirectReportPerson[] | undefined,
  handbook: IHandbook,
) => {
  if (!directReports) return 0

  return directReports.reduce((count, person) => {
    const recipient = person.handbookRecipients.find(
      (r) => r.handbook.id === handbook.id,
    )
    if (recipient?.isSignatureRequired && !recipient.hasValidSignature) {
      count += 1
    }
    return count
  }, 0)
}

export const AudienceHandbook: React.FC<AudienceHandbookProps> = ({
  handbook,
}) => {
  const allowManagers = useFeatureFlagEnabled(FeatureFlagId.AllowManagers)
  const { primaryColor, accentColor } = useAudienceDashboardSettings()
  const directReports = useDirectReports()

  const isSignatureRequired = handbook.permissionIds.includes('handbook.sign')
  const annotations = handbook.annotations.filter(
    (annotation) => isSignatureRequired || !annotation.dismissedAt,
  )
  const annnotationBookmarks = new Set(
    annotations.map(formatAnnotationBookmark),
  )
  const annotationsCount = annnotationBookmarks.size

  const lastSignature = last(handbook.signatures)

  const unsignedPeopleCount = getUnsignedPeopleCount(directReports, handbook)

  return (
    <Link
      className='tw-block lg:tw-flex tw-my-4 lg:tw-my-8 tw-py-4 lg:tw-py-8 tw-px-4 lg:tw-px-10 tw-items-center tw-justify-between tw-text-gray-500 tw-shadow-card tw-rounded'
      data-handbook-id={handbook.id}
      href={handbook.path}
      css={{
        background: 'white',
        border: '1px solid white',
        position: 'relative',
        transition: `all ${transitionDuration} linear`,
        h2: {
          color: primaryColor,
          transition: `all ${transitionDuration} linear`,
        },
        '&:hover': {
          background: '#fcfcfc',
          borderColor: colors['gray-300'],
          textDecoration: 'none',
          h2: {
            color: tinycolor(primaryColor).darken(10).toHexString(),
          },
        },
        [`@media (max-width: ${gridBreakpoints.lg}px)`]: {
          fontSize: 12,
        },
      }}
      target='_blank'
    >
      <div>
        <h2
          className='tw-my-0'
          css={css`
            @media (max-width: ${gridBreakpoints.lg}px) {
              font-size: 16px;
            }
          `}
        >
          {handbook.name}
        </h2>
        <div className='tw-mt-2'>
          <div className='tw-block lg:tw-inline-block tw-mr-2'>
            Last Updated:{' '}
            {moment(handbook.lastPublishedAt).format('YYYY-MM-DD')}
          </div>

          {lastSignature && (
            <div className='tw-block lg:tw-inline-block'>
              <FontAwesomeIcon
                className='tw-hidden lg:tw-inline tw-mr-2'
                icon='circle'
                style={{ fontSize: 4, transform: 'translateY(-1em)' }}
              />

              <span className='tw-mr-1'>
                You last signed:{' '}
                <SignHistoryModal.Button
                  color='link'
                  modalProps={{ handbook }}
                  style={{
                    color: 'inherit',
                    fontSize: 'inherit',
                    margin: '-5px 0 0',
                    textDecoration: 'underline',
                  }}
                >
                  {moment(lastSignature.signedAt).format('YYYY-MM-DD')}
                </SignHistoryModal.Button>
              </span>

              <FontAwesomeIcon
                color={colors['gray-600']}
                icon={['far', 'check']}
              />
            </div>
          )}
        </div>
      </div>

      <div className='lg:tw-flex tw-items-center'>
        {annotationsCount > 0 && (
          <HandbookIndicator
            count={annotationsCount}
            icon={['far', 'comment-alt-lines']}
            label={`${pluralize('change', annotationsCount)} since you last ${
              isSignatureRequired ? 'signed' : 'viewed'
            }.`}
            tooltip={`${pluralize('change', annotationsCount, true)} ${
              annotationsCount === 1 ? 'has' : 'have'
            } been made since you last ${
              isSignatureRequired ? 'signed' : 'viewed'
            }.`}
          />
        )}

        {allowManagers && unsignedPeopleCount > 0 && (
          <HandbookIndicator
            count={unsignedPeopleCount}
            icon='users'
            to={'/manager'}
            tooltip={`${unsignedPeopleCount} of your direct reports still need to sign their ${handbook.name} acknowledgement.`}
          />
        )}
      </div>

      {isSignatureRequired && (
        <div
          className='tw-flex tw-items-center tw-justify-between'
          css={css`
            padding: 5px 10px;
            @media (max-width: ${gridBreakpoints.lg}px) {
              padding: 3px 6px;
            }
          `}
          style={{
            backgroundColor: accentColor,
            color: getContrastColor(accentColor),
            position: 'absolute',
            top: -10,
            right: -10,
            borderRadius: 3,
          }}
        >
          SIGNATURE REQUIRED
        </div>
      )}
    </Link>
  )
}
